// const HOST = "10.8.21.113:8000";

const prod = {
  url: {
    // API_URL: `https://crytop.appsndevs.com/reviewapi/`,
    GOOGLE_API_URL: `https://maps.googleapis.com/maps/api`,
    GOOGLE_API_KEY: `AIzaSyD_vGGVm-ATDIapeInR3hPNzRHAMkKnqfw`,
    CLIENT_ID:
      "417720805067-a7n9mlo7ciae5dfebflun1e12m1cdqlq.apps.googleusercontent.com",
    Facebook_APPID: "4475162612701279",

    // API_URL: "https://prodapi.reviewattendant.com/",
    // PROFILEURI: "https://prodapi.reviewattendant.com/static/img/",
  },
};

const dev = {
  url: {
    GOOGLE_API_URL: `https://maps.googleapis.com/maps/api/`,
    GOOGLE_API_KEY: `AIzaSyD_vGGVm-ATDIapeInR3hPNzRHAMkKnqfw`,

    CLIENT_ID:
      "417720805067-a7n9mlo7ciae5dfebflun1e12m1cdqlq.apps.googleusercontent.com",
    Facebook_APPID: "4475162612701279",

    // //local
    // API_URL: `http://10.8.18.79:8003/`,
    // PROFILEURI: "http://10.8.18.79:8003/static/img/",

    //stagging
    // API_URL: "https://api.reviewattendant.com/",
    // PROFILEURI: "https://api.reviewattendant.com/static/img/",

    // Production - URL
    API_URL: "https://prodapi.reviewattendant.com/",
    PROFILEURI: "https://prodapi.reviewattendant.com/static/img/",
  },
};

export const config = process.env.REACT_APP_API_URL === "true" ? dev : dev;
