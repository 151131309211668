import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Paper,
  Typography,
  Avatar,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
} from "@mui/material";
import { Grid } from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import MDBox from "components/MDBox";
import ChatIcon from "@mui/icons-material/Chat";
import logout from "../../../src/assets/images/logout.svg";
import newChat from "../../../src/assets/images/newChat.svg";
import logo from "../../../src/assets/images/logoBlue.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import bgImage from "../../assets/images/chatBG.png";
import chatImage from "../../../src/assets/images/chat.jpg";
import backDashboard from "../../../src/assets/images/backDashboard.svg";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAysnc } from "features/actions/auth";
import burceMars from "assets/images/dummy.png";

import { chatResponseAsync } from "features/actions/chat";
import moment from "moment/moment";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import sendArrow from "../../../src/assets/images/icons/send-vector.svg";

import { config } from "constant";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

let questionStatic = [
  {
    question: "What is the latest review for my restaurant?",
  },
  {
    question: "Total number of reviews overall",
  },
  {
    question: "What are the top 5 best-selling drinks?",
  },
  {
    question: "Top 5 food items for my restaurant?",
  },
];
const PROFILEURI = config?.url?.PROFILEURI;
const ChatPage = () => {
  const messagesEndRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const {
  //   commonId
  // } = useSelector((state) => state.dashboard)
  const { getprofile } = useSelector((state) => state.auth);
  const { chatResponse, askQuestion, chatLoading } = useSelector(
    (state) => state.chat
  );
  const [chatState, setChatState] = useState();
  const [inputMessage, setInputMessage] = useState("");
  const [currentChat, setCurrentChat] = useState();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const commonId = localStorage.getItem("common_id");

  useEffect(() => {
    if (Object.keys(chatResponse)?.length > 0) {
      if (chatResponse?.Answer != null) {
        let date = new Date();
        const responseChat = {
          reciever: chatResponse?.Answer,
          recieverDateTime: date,
        };
        if (responseChat) {
          setMessages([...messages, responseChat]);
        }
      }
      if (chatResponse?.Answer == null) {
        let date = new Date();
        const responseChat = {
          reciever:
            "we couldn't find any response, Please provide the proper information regrading your question. ",
          recieverDateTime: date,
        };
        if (responseChat) {
          setMessages([...messages, responseChat]);
        }
      }
    }
  }, [chatResponse]);

  const handleMessage = (e) => {
    setInputMessage(e.target.value);
  };

  useEffect(() => {
    if (inputMessage) {
      setCurrentChat(inputMessage);
    }
  }, [inputMessage]);

  const sendData = () => {
    if (currentChat) {
      setChatState(true);
    }
  };

  useEffect(() => {
    if (chatState && currentChat) {
      let date = new Date();
      const newMessage = { sender: currentChat, senderDateTime: date };
      setMessages([...messages, newMessage]);
      setInputMessage("");
      dispatch(
        chatResponseAsync({
          common_id: commonId,
          query: currentChat,
        })
      )
        .then((response) => {
          setChatState(false);
          setCurrentChat("");
          window.scrollBy(0, 100);
        })
        .catch((error) => {
          // setLoading(false);
        });
    }
  }, [chatState]);

  useEffect(() => {
    dispatch(getProfileAysnc());
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/sign-in");
  };
  const handleClearChat = () => {
    setMessages([]);
  };

  const handleAskQuestion = (question) => {
    const newMessage = { sender: question };
    setMessages([...messages, newMessage]);
    setInputMessage("");
    dispatch(
      chatResponseAsync({
        common_id: commonId && commonId.toString(),
        query: question,
      })
    )
      .then((response) => {
        setChatState(false);
      })
      .catch((error) => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    if (chatLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [chatLoading]);

  useEffect(() => {
    if (askQuestion) {
      const newMessage = { sender: askQuestion };
      setMessages([...messages, newMessage]);
      setInputMessage("");
      dispatch(
        chatResponseAsync({
          common_id: commonId && commonId.toString(),
          query: askQuestion,
        })
      )
        .then((response) => {
          setChatState(false);
        })
        .catch((error) => {
          // setLoading(false);
        });
    }
  }, [askQuestion]);

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll to bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <div className="chatBot">
        <div className="outer-sidenav-chatbot">
          <div className="inner-sidenav-chatbot">
            <div className="chatBotSidebar">
              <div className="sidebar">
                <div className="logo">
                  <NavLink to="/dashboard">
                    <img src={logo} alt="Logo icon" />
                  </NavLink>
                </div>
                <div className="btnOuter">
                  <button className="newChat" onClick={handleClearChat}>
                    New Chat <img src={newChat} alt="Plus Icon" />
                  </button>
                </div>
              </div>
              <div className="logOut">
                <div
                  className="item"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/profile")}
                >
                  <img
                    src={
                      getprofile?.userProfile?.profilePicture
                        ? `${PROFILEURI}/${getprofile?.userProfile?.profilePicture}`
                        : "https://images.pexels.com/photos/762020/pexels-photo-762020.jpeg?auto=compress&cs=tinysrgb&w=600"
                    }
                    alt="User Image"
                    className="userLogoutImage"
                  />
                  <div className="name">
                    {getprofile?.userProfile?.firstName}{" "}
                    {getprofile?.userProfile?.lastName}
                  </div>
                </div>
                <div className="logOutBtn" onClick={handleLogout}>
                  <img src={logout} alt="Logout Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="chatSectionOuter">
          <div className="bgImage">
            <img src={bgImage} />
          </div>
          {getprofile?.businesses?.length > 0 ? (
            <Button
              sx={{ fontFamily: "urbanist" }}
              component={Link}
              className="backD"
              to="/dashboard"
            >
              <img src={backDashboard} /> Back to Dashboard
            </Button>
          ) : null}

          <div className="chatContainer">
            {messages?.length > 0 ? (
              ""
            ) : (
              <div className="helpSectionInner">
                <div className="chatArea">
                  <div className="helpOuter">
                    <div>
                      <div className="defaultTitle">
                        How can I help you with your reviews?
                      </div>
                      <div className="help">
                        {questionStatic?.map((ele) => {
                          return (
                            <div
                              className="item"
                              onClick={() => {
                                handleAskQuestion(ele?.question);
                              }}
                            >
                              {ele?.question}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="chatSectionInner">
              <div className="commonSection">
                {/* <div className="mainChatArea"> */}
                <div className="mainChatAreaInner">
                  <Paper
                    sx={{ background: "transparent" }}
                    elevation={0}
                    className="chatting"
                  >
                    {messages?.map((message, index) => {
                      return (
                        <>
                          <div
                            useRef={messagesEndRef}
                            className={
                              "chatItem" + (!message?.sender ? " response" : "")
                            }
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              marginBottom: "15px",
                              position: "relative",
                              justifyContent: message.sender
                                ? "flex-end"
                                : "flex-start",
                            }}
                          >
                            <div style={{ marginBottom: "47px" }}>
                              <div
                                className="senderName"
                                style={{ fontWeight: "bold" }}
                              >
                                {message.sender
                                  ? getprofile?.userProfile?.firstName !=
                                      undefined &&
                                    getprofile?.userProfile?.lastName !=
                                      undefined
                                    ? getprofile?.userProfile?.firstName +
                                      " " +
                                      getprofile?.userProfile?.lastName
                                    : "User"
                                  : "AI Assistant"}
                                <span className="time">
                                  {" "}
                                  {message.sender
                                    ? moment(message.senderDateTime).fromNow()
                                    : moment(
                                        message.receiverDateTime
                                      ).fromNow()}
                                </span>
                              </div>
                              <div className="message">
                                <div variant="body1">{message?.sender}</div>
                                <div>
                                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                    {message?.reciever}
                                  </ReactMarkdown>
                                </div>
                                <div className="userImage">
                                  <img
                                    className="senderImageSize"
                                    src={
                                      message?.sender
                                        ? getprofile?.userProfile
                                            ?.profilePicture
                                          ? `${PROFILEURI}/${getprofile?.userProfile?.profilePicture}`
                                          : burceMars
                                        : chatImage
                                    } // Use the same image for both senders
                                    // alt={}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div ref={messagesEndRef} />
                        </>
                      );
                    })}
                  </Paper>
                </div>
                {/* </div> */}
              </div>
            </div>
            <div className="bottomContentOuter">
              <div className="bottomContainer">
                <div className="bottomContent marginT">
                  {loading ? (
                    <div class="loader-container">
                      <div class="loader-bar"></div>
                    </div>
                  ) : null}
                  <div className="searchQ ">
                    <div style={{ width: "100%", position: "relative" }}>
                      <input
                        placeholder="Send a message"
                        value={inputMessage}
                        onChange={(e) => handleMessage(e)}
                        className="textField"
                        onKeyPress={(event) =>
                          event.key === "Enter" ? sendData() : null
                        }
                      />

                      <button
                        onClick={sendData}
                        className="submitBtn"
                        style={{ cursor: "pointer" }}
                      >
                        {/* Submit */}
                        <img src={sendArrow} />
                      </button>
                    </div>
                  </div>
                  <div className="copyright">
                    <p>© 2024 Review Attendant. All Rights Reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatPage;
