import React from "react";
import { Typography, Box } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import MDTypography from "components/MDTypography";
import maintenanceImage from "../../assets/images/maint.png";

const Maintenance = () => {
  return (
    <div>

      <img src={maintenanceImage} className="maintenance-image"/>
    </div>
  );
};

export default Maintenance;
